<template>
  <div class="bb-onboarding" v-if="listAvailable">
    <div class="bg-cercles bb-onboarding__container bb-onboarding__container--guest">
      <main class="og2 bb-container">
        <div class="og2__container">
          <div class="og2__content">
            <div class="og2__title">¿Cual es la mejor forma de ayudar a {{ myList.ownerName }}?</div>
            <div class="og2__subtitle">
              Compra los regalos que marca la abejita de HelloBB para:
            </div>
            <div class="og2__list">
              <div class="og2__list-item">Cambios y devoluciones fáciles.</div>
              <div class="og2__list-item">
                Que {{ myList.ownerName }} pueda acumular el 5% de todo lo que le regales.
              </div>
            </div>
          </div>

          <picture class="og2__cover">
            <source
              srcset="
                ../../assets/img/covers/cover-onboading-guest-screenshot.png    1x,
                ../../assets/img/covers/cover-onboading-guest-screenshot@2x.png 2x
              "
              media="(min-width: 992px)"
            />
            <source
              srcset="
                ../../assets/img/covers/cover-onboading-guest-screenshot-vertical.png    1x,
                ../../assets/img/covers/cover-onboading-guest-screenshot-vertical@2x.png 2x
              "
              media="(max-width: 991px)"
            />
            <img
              loading="lazy"
              src="../../assets/img/covers/cover-onboading-guest-screenshot@2x.png"
              alt="¡Compralo en HelloBB!"
            />
          </picture>
        </div>
        <aside class="og1__actions">
          <div class="nav-onboarding-guest">
            <div class="nav-onboarding-guest__item">
              <router-link :to="{ name: 'OnboardingGuestStep1', params: { id: myList.id } }" class="nav-onboarding-guest__arrow">
                <img src="../../assets/img/ico/ico-arrow-slick-left.svg" alt="" />
              </router-link>
            </div>
            <div class="nav-onboarding-guest__item">
              <router-link
                :to="{ name: 'OnboardingGuestStep3', params: { id: myList.id } }"
                class="nav-onboarding-guest__arrow nav-onboarding-guest__arrow--right"
              >
                <img src="../../assets/img/ico/ico-arrow-slick-left.svg" alt="" />
              </router-link>
            </div>
          </div>
        </aside>
      </main>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CanGetGuestList from "../../Mixins/CanGetGuestList";

export default {
  name: "OnboardingGuestStep2",
  mixins: [CanGetGuestList],
  computed: {
    ...mapGetters({
      myList: "myList",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
